@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/** ---- Frist Section ---- **/

.first {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000;
}

.first h2 {
  position: relative;
  font-size: 3.5em;
  font-weight: 900;
  color: #fff;
  z-index: 1;
  overflow: hidden;
}

.first h2:before {
  content: '';
  position: absolute;
  left: -20%;
  width: 120%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #000 5%, #000 100%);
  animation: oppening 4s ease-out forwards;
  animation-delay: 0.75s;
}

.first .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
  mix-blend-mode: screen;
}

/** ---- Broken Section ---- **/

.broken {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 300vh;
}

.broken .side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/logo.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: #4f6d7a;
}

section .side#side1 {
  clip-path: polygon(
    0% 0%,
    46% 0%,
    67% 9%,
    25% 27%,
    69% 33%,
    39% 45%,
    57% 56%,
    27% 68%,
    55% 81%,
    28% 87%,
    54% 92%,
    43% 100%,
    0% 100%
  );
}

section .side#side2 {
  clip-path: polygon(
    100% 0%,
    46% 0%,
    67% 9%,
    25% 27%,
    69% 33%,
    39% 45%,
    57% 56%,
    27% 68%,
    55% 81%,
    28% 87%,
    54% 92%,
    43% 100%,
    100% 100%
  );
}

#message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#message h2 {
  color: #dbe9ee;
  font-size: 3.5em;
  font-weight: 900;
  animation: breath 2s ease-out both infinite;
}

/** ---- Content Section ---- **/

#content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: auto;
  width: 100%;
  opacity: 0%;
}

.headerContainer {
  height: 100vh;
}

.header {
  width: 100%;
  height: 100vh;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 81%);
  background: #0078bd;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.imgContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 5%;
}

.imgContainer img {
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 3px 7px 17px 4px black;
  -webkit-box-shadow: 3px 7px 17px 4px black;
}

.textContainer {
  width: 100%;
  text-align: center;
}

.textContainer h1 {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 4.5em;
}
.textContainer p {
  color: white;
  font-size: 2.5em;
}

#keepScrolling {
  position: relative;
  bottom: 80px;
  left: 15px;
  font-size: 1.5em;
  color: #0078db;
}

/** ---- Porfolio Section ---- **/

#portfolioContainer {
  height: 100vh;
  background: #4f6d7a;
}

#portfolio {
  width: 100%;
  height: 100vh;
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 80%);
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #fff;
}

#portfolio h1 {
  margin-top: 5%;
  color: #0078bd;
  font-size: 2.5em;
}

#portfolio .cardContainer {
  width: 80%;
  height: 65%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  align-items: center;
  margin-top: 5%;
}

#portfolio .cardContainer .card {
  position: relative;
  margin: 0 auto;
}

#portfolio .cardContainer .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

#portfolio .cardContainer .card .face1 {
  position: relative;
  background: #4f6d7a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

#portfolio .cardContainer .card:hover .face1 {
  background: #0078bd;
  transform: translateY(0);
}

#portfolio .cardContainer .card .face1 .content {
  opacity: 0.2;
  transition: 0.5s;
  width: 200px;
  text-align: center;
}

#portfolio .cardContainer .card:hover .face1 .content {
  opacity: 1;
}

#portfolio .cardContainer .card .face1 .content img {
  max-width: 100px;
}

#portfolio .cardContainer .card .face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

#portfolio .cardContainer .card .face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

#portfolio .cardContainer .card:hover .face2 {
  transform: translateY(0);
}

#portfolio .cardContainer .card .face2 .content p {
  margin: 0;
  padding: 0;
}

#portfolio .cardContainer .card .face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #0078bd;
  padding: 5px;
  border: 1px solid #0078bd;
}

#portfolio .cardContainer .card .face2 .content a i {
  margin: 0 4px;
}

#portfolio .cardContainer .card .face2 .content a:hover {
  background: #0078bd;
  color: #fff;
}

/** ---- Skills Section ---- **/

#skillsContainer {
  background: #dbe9ee;
  color: #dbe9ee;
  height: 100vh;
}

#skills {
  height: 100vh;
  background-color: #4f6d7a;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 81%);
}

.textCenterContainer {
  text-align: center;
  font-size: 2.5em;
}

#languagesContainer {
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1em;
}

#languagesContainer div {
  text-align: center;
}

.languagesImgContainer {
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1em;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 40%;
  margin: auto;
  transition: 0.5s;
}

.tooltip .tooltipText {
  opacity: 0;
  width: 0;
  background-color: #dbe9ee;
  color: #4f6d7a;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: bolder;

  position: absolute;
  z-index: 1;
  transition: 0.5s;
}

.tooltip:hover .tooltipText {
  opacity: 1;
  width: 100px;
}

#languagesContainer div div img {
  width: 100%;
}

.tooltip:hover {
  width: 55%;
}

#languagesContainer div h3 {
  font-size: 1.5em;
}

/** ---- recomendation Section ---- **/

#recomendationContainer {
  background-color: #4f6d7a;
  height: 100vh;
}

#recomendation {
  height: 100vh;
  background-color: #dbe9ee;
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 80%);
}

#boxContainer {
  height: 750px !important;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
}

#boxContainer .box {
  position: relative;
  padding: 40px;
  height: 300px;
  transition: 0.5s;
  text-align: center;
  background: #4f6d7a;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#boxContainer .box:hover {
  background: #dbe9ee;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
}

#boxContainer .box p {
  margin: 10px 0 0;
  padding: 0;
  transition: 0.5s;
  color: #dbe9ee;
}

#boxContainer .box h4 {
  margin: 20px 0 0;
  padding: 0;
  transition: 0.5s;
  font-size: 2em;
  font-weight: bolder;
  line-height: 20px;
  color: #dbe9ee;
  text-transform: uppercase;
  display: flex;
  flex-flow: column;
}

#boxContainer .box:hover h4,
#boxContainer .box:hover p,
#boxContainer .box:hover h4 span {
  color: #4f6d7a;
}

#boxContainer .box h4 span {
  font-weight: 500;
  font-size: 1em;
  color: #c5c5c5;
  transition: 0.5s;
  margin-top: 5%;
}

#boxContainer .box:before {
  content: '';
  position: absolute;
  top: 10px;
  right: 40px;
  width: 80px;
  height: 80px;
  background: url('./assets/quotes.png');
  background-size: cover;
  opacity: 0.05;
  pointer-events: none;
  transition: 0.5s;
  transform: rotate(180deg);
}

#boxContainer .box:hover:before {
  opacity: 1;
  transform: rotate(180deg) translateY(35px);
}

#boxContainer .box:after {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 40px;
  width: 80px;
  height: 80px;
  background: url('./assets/quotes.png');
  background-size: cover;
  opacity: 0.05;
  pointer-events: none;
  transition: 0.5s;
}

#boxContainer .box:hover:after {
  opacity: 1;
  transform: translateY(-40px);
}

/** ---- Footer Section ---- **/

.footer {
  height: 300px;
  color: #dbe9ee;
  background: #4f6d7a;
}

.footer ul {
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
}

.footer ul li {
  list-style: none;
}

.footer ul li a {
  position: relative;
  width: 60px;
  height: 60px;
  display: block;
  text-align: center;
  margin: 0 10px;
  border-radius: 50%;
  padding: 6px;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  background: linear-gradient(#fff, #dbe9ee);
  transition: 0.5s;
}

.footer ul li a:hover {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.footer ul li a i {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(#dbe9ee, #ffff);
  border-radius: 50%;
  line-height: calc(60px - 12px);
  font-size: 24px;
  color: #262626;
  transition: 0.5s;
}

.footer ul li:nth-child(1) a i:hover {
  color: #00aced;
}
.footer ul li:nth-child(2) a i:hover {
  color: #fff;
  background: black;
}
.footer ul li:nth-child(3) a i:hover {
  color: #007bb6;
}
.footer ul li:nth-child(4) a i:hover {
  color: #bc2a8d;
}
.footer ul li:nth-child(5) a i:hover {
  color: #c55247;
}

.footerRow {
  height: 50%;
  text-align: center;
}

.col:nth-child(1) {
  padding-top: 5%;
}

.row:nth-child(2) .col {
  font-weight: bolder;
  font-size: 1.5em;
}

/** ---- CV Button Section ---- **/
#cv {
  width: 80px;
  height: 80px;
  position: fixed;
  background-color: white;

  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  letter-spacing: 2px;
  font-weight: bold;
  border: 4px solid #0078db;
  transition: 0.5s;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

#cv a {
  text-decoration: none;
  color: #0078db;
}

#cv a:active {
  text-decoration: none;
  color: #0078db;
}

#cv:hover {
  background-color: #0078db;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

#cv:hover a {
  color: #fff;
}

/** ---- ScrollBar Section ---- **/

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(#fff, #0078bd);
  border-radius: 6px;
}

@media (max-width: 1400px) {
  .tooltip {
    width: 45%;
  }

  .tooltip:hover {
    width: 60%;
  }

  .tooltip:hover .tooltipText {
    width: 60px;
  }

  #recomendationContainer {
    height: 130vh;
  }

  #recomendation {
    height: 130vh;
  }

  #boxContainer {
    height: 900px !important;
  }

  #recomendation .textCenterContainer {
    margin-bottom: 5%;
  }
}

@media (max-height: 750px) {
  .header {
    height: 110vh;
  }

  .headerContainer {
    height: 110vh;
  }

  #keepScrolling {
    position: relative;
    bottom: 90px;
  }

  #portfolioContainer {
    height: 150vh;
  }

  #portfolio {
    height: 150vh;
  }

  .tooltip {
    width: 30%;
  }

  .tooltip:hover {
    width: 45%;
  }

  #recomendationContainer {
    height: 220vh !important;
  }

  #recomendation {
    height: 220vh !important;
  }

  #boxContainer {
    height: 1650px !important !important;
  }

  #boxContainer .box:after {
    left: 20px;
  }

  #recomendation .textCenterContainer {
    margin-bottom: 5%;
  }

  .box {
    margin-top: 5%;
  }
}

@media (max-width: 800px) {
  #keepScrolling {
    position: relative;
    bottom: 40px;
  }

  #portfolioContainer {
    height: 200vh;
  }

  #portfolio {
    height: 200vh;
  }

  .tooltip {
    width: 30%;
  }

  .tooltip:hover {
    width: 45%;
  }

  #recomendationContainer {
    height: 180vh !important;
  }

  #recomendation {
    height: 180vh !important;
  }

  #boxContainer {
    height: 1250px !important !important;
  }

  #recomendation .textCenterContainer {
    margin-bottom: 5%;
  }

  .box {
    margin-top: 5%;
  }
}

@media (max-width: 450px) {
  body {
    overflow-x: hidden;
  }

  * {
    font-size: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
  }

  .first h2:before {
    animation-delay: 1.4s;
  }

  .broken .side {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/logo.svg');
    background-repeat: no-repeat;
    background-color: #4f6d7a;
  }

  #message {
    position: absolute;
    left: 0;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  #message h2 {
    color: #dbe9ee;
    font-size: 14px;
    font-weight: 900;
  }

  .headerContainer {
    height: 180vh;
  }

  .header {
    height: 180vh;
  }

  #keepScrolling {
    position: relative;
    bottom: 30px;
    left: 0;
  }

  #portfolioContainer {
    height: 340vh;
  }

  #portfolio {
    height: 340vh;
  }

  #skillsContainer {
    height: 240vh;
  }

  #skills {
    height: 240vh;
  }

  #languagesContainer {
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  }

  .tooltip {
    width: 25%;
  }

  .tooltip:hover {
    width: 45%;
  }

  #recomendationContainer {
    height: 300vh !important;
  }

  #recomendation {
    height: 300vh !important;
  }

  #boxContainer {
    height: 1550px !important !important;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  }

  #boxContainer .box:after {
    left: 5px;
    bottom: 5px;
  }

  #recomendation .textCenterContainer {
    margin-bottom: 5%;
  }

  .box {
    margin-top: 5%;
  }

  .footer ul li a {
    width: 52px;
    height: 52px;
  }
}

/** ---- KeyFrames Section ---- **/

@keyframes oppening {
  from {
    left: -20%;
  }
  to {
    left: 100%;
  }
}

@keyframes breath {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    font-size: 4em;
  }

  100% {
    opacity: 0;
  }
}
